
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import {useRoute, useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-change",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const submitButton = ref<HTMLElement | null>(null);
    const newPassword = ref<string>("");
    const confirmPassword = ref<string>("");

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string()
          .email()
          .required()
          .label("Email")
    });

    //Form submit function
    const onSubmitForgotPassword = values => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      store
          .dispatch(Actions.FORGOT_PASSWORD_SAVE, {
            email: route.query.email,
            password: newPassword.value,
            'password_confirmation': confirmPassword.value,
            token: route.params.token
          })
          .then(() => {
            Swal.fire({
              text: "Password changed successfully! You can now log in.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(function() {
              // Go to page after successfully login
              router.push({ name: "dashboard" });
            });

            submitButton.value?.removeAttribute("data-kt-indicator");
          })
          .catch(() => {
            // Alert then login failed
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });

            submitButton.value?.removeAttribute("data-kt-indicator");
          });
    };

    return {
      onSubmitForgotPassword,
      newPassword,
      confirmPassword,
      forgotPassword,
      submitButton
    };
  }
});
